import React, {Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import LoadingSuspense from '@mp/mp-loading-mask-suspense';

const PageIndex = React.lazy(() => import('./Pages/PageIndex'));

const Root = (props) => {
  
    return (
        <LoadingSuspense>   
            <Router>
                <PageIndex {...props}/>
            </Router>
        </LoadingSuspense> 
    )
}

export default Root;
